<template>
  <FormsGenerator />
</template>

<script>
import FormsGenerator from '../../crm-form-generator/FormsGenerator.vue';

export default {
  name: 'FormGenerator',
  components: {FormsGenerator},
};
</script>

<style scoped>

</style>
