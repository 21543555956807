<template>
  <div
      :class="classGenerator"
      class="formGenerator"
  >
    <div class="row">
      <div
          :class="[column.class]"
          :key="index"
          v-for="(column, index) in schema"
      >
        <component
            :backend_errors="backend_errors"
            :data="data"
            :dictionaries="dictionaries"
            :field="field"
            :inputRequiredEmpty="inputRequiredEmpty"
            :is="fields[getCurrentNameField(field) + '' + mode]"
            :key="index"
            :mode="mode"
            :schemaVersion="schemaVersion"
            v-for="(field, index) in column.fields"
        >
        </component>
      </div>
      <slot name="staticScope"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormsGenerator',
  props: ['classGenerator', 'schema', 'schemaVersion', 'backend_errors', 'inputRequiredEmpty', 'data', 'dictionaries', 'mode'],
  data() {
    return {
      fields: [],
    };
  },
  watch: {
    mode(newValue) {
      if (newValue) this.loader();
    },
    schema(newValue) {
      if (newValue) this.loader();
    },
  },
  async created() {
    await this.loader();
  },
  methods: {
    async loader() {
      if (this.schema) {
        this.schema.forEach((column) => {
          column.fields.forEach((item) => {
            let currentNameField = this.getCurrentNameField(item);
            item.meta = this.parseValidationRules(item.validation);
            if (currentNameField) {
              this.fields[currentNameField + '' + this.mode] = () => import(`./src/fields/${currentNameField}/${this.mode !== 'read' ? 'CreateOrUpdate' : 'index'}.vue`);
              this.fields[currentNameField + '' + this.mode].separator = column.separators ? column.separators : true;
            }
          });
        });
      }
    },
    getCurrentNameField(field) {
      if (field.inputType) return 'field' + field.inputType.charAt(0).toUpperCase() + field.inputType.slice(1);
      return '';
    },
    parseValidationRules(validation) {
      let meta = {};
      const validationRules = validation.split('|');
      validationRules.forEach((validationRule) => {
        const rule = validationRule.split(':');
        switch (rule[0]) {
          case 'required':
            meta.required = true;
            break;
          case 'max':
            meta.maxLength = parseInt(rule[1]);
            break;
          case 'min':
            meta.minLength = parseInt(rule[1]);
            break;
          case 'pattern':
            meta.pattern = rule[1];
            break;
        }
      });
      return meta;
    },
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
//
</style>
<style
    lang="scss"
    rel="stylesheet/scss"
>
.labelWrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
}

.formGenerator {
  background-color: #fff;

  .generator--form-item:last-child {
    .separator {
      border: none;
    }
  }

  .el-form-item__label {
    padding: 0 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
  }

  .el-select {
    width: 100%;
  }
}

.el-form--labelPosition-top {
  display: block;
}

.el-form--labelPosition-left {
  display: flex;
}

.el-form--labelPosition-right {
  display: flex;
  flex-flow: row-reverse;
}

.el-form--labelPosition-top,
.el-form--labelPosition-left,
.el-form--labelPosition-right {
  .el-form-item__content {
    width: 100%;
    margin: 0 !important;
  }
}

.el-form--labelPosition-top,
.el-form--labelPosition-left {
  .el-form-item__label {
    text-align: left;
  }
}

.el-form--labelPosition-right {
  .el-form-item__label {
    text-align: right;
  }
}
</style>
